<template>
  <v-col :cols="12"> 
    <span class="permission-label">Permissões</span> 
    <span class="permission-count-message">{{ permissionCountMessage }}</span>
    <div v-if="requiredError && !multiple" class="permission-error">
      Obrigatório selecionar ao menos uma permissão.
    </div>
    <expansion-panel
      v-if="multiple"
      accordion
      :class="{ 'required-error': requiredError }"
      :contentProps="{ eager: true }"
      :items="clientPanels"
    >
      <template v-slot:header="{ item: { empty, fantasia, permissionCountMessage } }">
        {{ fantasia }}
        <span v-if="permissionCountMessage" class="permission-count-message">{{ permissionCountMessage }}</span>
        <span v-if="empty && requiredError" class="permission-error">
          Obrigatório selecionar ao menos uma permissão.
        </span>
      </template>
      <template v-slot:content="{ item: { id } }">
        <permission-panels
          :items="permissoesList"
          :sending="sending"
          :value="getPermissionById(id)"
          @change="setUserPermissionPerClient(id, $event)"
        ></permission-panels>
      </template>
    </expansion-panel>
    <permission-panels
      v-else
      :class="{ 'required-error': requiredError }"
      :items="permissoesList"
      :sending="sending"
      :value="value"
      @change="$emit('change', $event)"
    ></permission-panels>
  </v-col>
</template>

<script>
import { cloneDeep, isFinite } from 'lodash';
import { UserTypeEnum } from '@/core/enums/user-types';

export default {
  components: {
    'expansion-panel': () => import('@/components/expansion-panel.vue'),
    'permission-panels': () => import('./permissionPanels.vue'),
  },
  computed: {
    clientPanels: function () {
      const empresas = Array.isArray(this.id) ? this.id : isFinite(this.id) ? [this.id] : [];
      return empresas.map((id) => {
        const { fantasia } = this.opts.clientes.find((cliente) => cliente.id === id) || {};
        const permissionsFounded = this.getPermissionById(id);
        const length = permissionsFounded?.length || 0;
        const permissionCountMessage = length > 0 ? `(${length} permissões selecionadas)` : '';
        const empty = length === 0;
        return { id, empty, fantasia, permissionCountMessage };
      });
    },
    permissionCountMessage: function () {
      const length = (!this.multiple && this.value?.length) || 0;
      return length > 0 ? `(${length} permissões selecionadas)` : '';
    },
  },
  created: function () {
    this.doLoadPermissoes();
    this.$emit('update:valid', this.validate);
  },
  data: function () {
    return {
      requiredError: false,
      permissoesList: {},
    };
  },
  methods: {
    doLoadPermissoes: async function () {
      try {
        this.$emit('update:sending', true);
        const resource = this.apiResource(`/v1/permissoes?tipo_usuario=${this.userType}`);
        const response = await resource.get();

        if (response.error) {
          throw response;
        }

        this.permissoesList = response;
      } catch (error) {
        this.$emit('error', error);
        this.permissoesList = {};
      } finally {
        this.$emit('update:sending', false);
      }
    },
    getPermissionById: function (id = null) {
      if (!id || (!Array.isArray(this.value) || this.value.length === 0)) {
        return [];
      }

      const parsed = typeof this.value[0] === 'string' ? this.value : (this.value.find(({ empresa_id }) => empresa_id === id)?.permissoes || []);
      return cloneDeep(parsed);
    },
    setUserPermissionPerClient: function (id = null, permissoes) {
      if (!id) {
        return;
      }

      const found = this.value.find(({ empresa_id }) => empresa_id === id);
      const clonedPermissoes = cloneDeep(permissoes || []);

      if (found) {
        found.permissoes = clonedPermissoes;
      } else {
        this.value = this.value.concat([{ empresa_id: id, permissoes: clonedPermissoes }]);
      }

      this.$emit('change', cloneDeep(this.value));
    },
    validate: function () {
      let hasPermission = false;

      if (this.multiple) {
        const empresas = Array.isArray(this.id) ? this.id : isFinite(this.id) ? [this.id] : [];
        hasPermission = Array.isArray(this.value) && this.value.length > 0 && empresas.length === this.value.length && this.value.every(({ permissoes }) => Array.isArray(permissoes) && permissoes.length > 0);
      } else {
        hasPermission = Array.isArray(this.value) && this.value.length > 0;
      }

      this.requiredError = !hasPermission;
      return hasPermission;
    },
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    id: {
      type: [Array, Number],
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    opts: {
      type: Object,
      default: () => ({}),
    },
    sending: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: Number,
      default: null,
    },
    valid: {
      type: Function,
    },
    value: {
      type: Array,
      default: () => ([]),
    }
  },
  watch: {
    userType: function (nextValue, prevValue) {
      const userTypeValues = Object.values(UserTypeEnum);

      if (nextValue != prevValue && !userTypeValues.includes(nextValue)) {
        throw new Error(`${nextValue} não é um tipo de usuário válido do sistema.`);
      }
    },
    value: function () {
      this.validate();
    },
  }
}
</script>

<style lang="scss" scoped>
.required-error {
  border: 2px solid #ca0d0d;
  border-radius: 10px 10px 0 0;
  padding-bottom: 2px;
}

.permission-label {
  color: rgba(0, 0, 0, .87);
  font-family: Poppins;
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: .01em;
  margin-left: .5rem;
}

.permission-count-message {
  font-size: .75rem;
  font-style: italic;
  font-weight: 300;
  margin-left: .5rem;
}

.permission-error {
	color: #ca0d0d !important;
  font-size: 12px;
	line-height: 12px;
	word-break: break-word;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
  margin: 0 .5rem .5rem;
}

.v-expansion-panel-header .permission-error {
  margin: 0 0 0 .5rem;
}
</style>
