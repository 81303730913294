var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"permission-label"},[_vm._v("Permissões")]),_c('span',{staticClass:"permission-count-message"},[_vm._v(_vm._s(_vm.permissionCountMessage))]),(_vm.requiredError && !_vm.multiple)?_c('div',{staticClass:"permission-error"},[_vm._v(" Obrigatório selecionar ao menos uma permissão. ")]):_vm._e(),(_vm.multiple)?_c('expansion-panel',{class:{ 'required-error': _vm.requiredError },attrs:{"accordion":"","contentProps":{ eager: true },"items":_vm.clientPanels},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var ref_item = ref.item;
var empty = ref_item.empty;
var fantasia = ref_item.fantasia;
var permissionCountMessage = ref_item.permissionCountMessage;
return [_vm._v(" "+_vm._s(fantasia)+" "),(permissionCountMessage)?_c('span',{staticClass:"permission-count-message"},[_vm._v(_vm._s(permissionCountMessage))]):_vm._e(),(empty && _vm.requiredError)?_c('span',{staticClass:"permission-error"},[_vm._v(" Obrigatório selecionar ao menos uma permissão. ")]):_vm._e()]}},{key:"content",fn:function(ref){
var id = ref.item.id;
return [_c('permission-panels',{attrs:{"items":_vm.permissoesList,"sending":_vm.sending,"value":_vm.getPermissionById(id)},on:{"change":function($event){return _vm.setUserPermissionPerClient(id, $event)}}})]}}],null,false,480674489)}):_c('permission-panels',{class:{ 'required-error': _vm.requiredError },attrs:{"items":_vm.permissoesList,"sending":_vm.sending,"value":_vm.value},on:{"change":function($event){return _vm.$emit('change', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }